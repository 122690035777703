<template>
  <div
    class="app-icon-card-vertical flex flex-col justify-center items-center text-center"
    :class="[dynamicClasses]"
    @click="redirectTo(!soon && url)"
  >
    <img :src="image.src" :alt="image.title" :class="sizesClasses" />
    <h1 class="mt-16 mb-8 title text-blue-400 text-20 leading-140 font-medium font-bbva">
      {{ title }}
    </h1>
    <p v-if="text" v-html="text" />
    <app-soon v-if="soon" />
  </div>
</template>

<script>
import redirectTo from '@/shared/utils/redirectTo'

export default {
  name: 'AppIconCardVertical',
  components: {
    AppSoon: () => import('@/components/AppSoon.vue'),
  },
  props: {
    image: {
      required: true,
      type: Object,
    },
    sizesClasses: {
      type: Array,
      default: () => ['w-32'],
    },
    title: {
      required: true,
      type: String,
    },
    text: {
      type: String,
    },
    soon: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  computed: {
    dynamicClasses() {
      return [this.theme, this.soon ? 'soon-opacity' : 'cursor-pointer']
    },
  },
  methods: {
    redirectTo,
  },
}
</script>

<style lang="scss" scoped>
.app-icon-card-vertical {
  h1,
  p {
    max-width: 384px;
  }

  p {
    margin: 0;
  }

  &.soon-opacity {
    opacity: 0.6;
  }

  .dark {
    .title {
      color: theme('colors.white');
    }
  }
}
</style>

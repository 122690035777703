import router from '@/router'

export default function redirectTo(url) {
  if (!url) {
    return
  }

  if (/^http/.test(url)) {
    window.open(url)
    return
  }

  router.push(url)
}

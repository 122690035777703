<template>
  <div class="social-items animated fadeIn flex flex-wrap justify-start">
    <app-icon-card-vertical
      v-for="item in cards"
      :key="item.id"
      class="w-1/3 mb-72 flex-1 cursor-pointer"
      :theme="theme"
      v-bind="item.card"
    />
  </div>
</template>

<script>
import AppIconCardVertical from '@/components/AppIconCardVertical.vue'

export default {
  name: 'SocialItems',
  components: {
    AppIconCardVertical,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
    },
  },
}
</script>
